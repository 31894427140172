@import '../../../assets/scss/variable.scss';

.root{
background: $white;
border: 1px solid $grey-2;
border-radius: 4px;
padding:25px;
display: flex;
flex-direction: column;
position: relative;
}

.root[uploadedBy="CIO"]{
    border: 2px solid $orange-2;
    margin-top: 35px;
    padding-top: 35px;
}

.crown{
    padding: 4px 16px 4px 16px;
    border:2px solid $orange-2;
    border-radius: 5px;
    position: absolute;
    top: -15px;
    left:15px;
    background: $white;
    display: flex;
    align-items: center;
    div{
        color: $orange-2;
    }
    font: 700 12px 'Roboto' , sans-serif;
    img{
        width: 20px;
        height: 20px;
        margin-left: 5px;
    }
}

.container{
display: flex;
align-items: center;
margin:10px;
}

.cautionIcon{
    width: 26px;
    height: 26px;
    filter: brightness(0) saturate(100%) invert(16%) sepia(100%) saturate(4206%) hue-rotate(354deg) brightness(94%) contrast(125%);
}

.textContainer{
display: flex;
width: 70%;
align-items: center;
// justify-content: space-between;
font-family: 'Roboto', sans-serif;
font-weight: 400;
color: $black;
font-size: 14px;
div{
    margin-left: 20px;
}
div:nth-of-type(2)
{
    margin-left: 40px;
}}

.index{
font-size: 16px;
font-weight: 500;
}

.lightText{
color: $grey-4;
}

.validationFailure{
margin-top: 20px;
background: $red-tint;

padding: 10px 10px;
border-radius: 6px;
display: flex;
flex-direction: row;
align-items: center;
// align-items:baseline;
// justify-content: flex-start;

i{
    font-size: 20px;
    margin-right: 10px;
    color:$red;
    position: relative;
    bottom:1px;
}
.errorHeading{
    font: 700 14px 'Roboto',sans-serif;
    color: $red;
    display: flex;
}
.errorText{
    font: 400 14px 'Roboto',sans-serif;
    color: $grey-4;
    line-height: 20px;
    margin-top: 10px;
}
}

.resultSectionContainer{
    max-height: 0; /* Start with the collapsed state */
    visibility: hidden;
    margin-bottom: 10px;
    transition: max-height 0.5s ease, visibility 0.2s ease, margin-bottom 0.5s ease;
    .smallHeading{
        font: 400 16px 'Roboto',sans-serif;
        color:$grey-4;
        line-height: 18.75px;
    }
}

.resultSectionContainer.open {
    max-height: 180px; /* Set a large enough max-height to fit your content */
    visibility: visible;
    margin-bottom: 20px;
}

.resultStatusContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    button{
     font: 500 14px 'Roboto',sans-serif;
        img{
        width: 20px;
        height: 20px;
        margin-left: 3px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(687%) hue-rotate(271deg) brightness(116%) contrast(100%);
        }
    }
}

@keyframes blinkBorder {
  0% {
    box-shadow: 0 0 0 rgba(255, 0, 0, 0); 
  }
  50% {
    box-shadow: 0 0 10px 1px rgba(255, 215, 0, 1);
  }
  100% {
    box-shadow: 0 0 0 rgba(255, 0, 0, 0); 
  }
}

.blinkingBorder {
  border: 2px solid transparent;
  animation: blinkBorder 2s ease-in-out infinite;
//   animation-duration: 2s;
  animation-iteration-count: 3;
}


.expand{
    // z-index: 100;
    background-color:$grey-0 ;
    width: 150px;
    align-self: center;
    // margin-top: 10px;
    cursor: pointer;
    padding:5px;
    display: flex;
    align-items:center ;
    justify-content: center;
    border-radius: 45px;
     font: 400 14px 'Roboto',sans-serif;
     color:$grey-4;
    img{
        margin-left: 5px;
        height: 20px;
        width: 20px;
    }


}

.sectionWiseButtonContainer{
    display: inline-flex;
    flex-direction: column;
    margin-top:15px;
    margin-bottom:-20px ;
    .sectionWiseButton{
        // visibility: hidden;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding:8px 15px;
        border-radius: 20px;
        border:1px solid $grey-2;
        .text{
            font:500 12px 'Roboto',sans-serif;
            color: $primary-color;
        }
        .icon{
            width: 16px;
            height: 16px;
            margin-left: 2px;
            filter: brightness(0) saturate(100%) invert(39%) sepia(6%) saturate(6515%) hue-rotate(184deg) brightness(98%) contrast(91%);
        }
    }
}

.warningText{
    font : 400 12px 'Roboto', sans-serif;
    color: $red;
    margin-top: 5px;

}

.horizontalLine{
border-top: 1px solid $grey-2;
color: $red;
margin:10px 0px 16px 0px;
}

:export{
    awaitingProcessing:$orange-1;
    processing :$pale-green;
    processed:$material-green;
    validating:$yellow;
    validationFailed:$red;
    ineligible:$magenta;
    processingFailed:$brown;
}