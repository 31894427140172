@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

 .tc-audit-file-management-content-local {
  @include important-text;
  margin-left: 0;
  .comman-head {
    display: flex;
    .management-title {
      flex: 1;
      h5 {
        margin-bottom: 0;
        line-height: 1.8;
      }
    }
  }

  .tc-audit-file-management-list {
    .tc-audit-file-list {
    //   box-shadow: $white-box-shadow;
    //   -moz-box-shadow: $white-box-shadow;
    //   -webkit-box-shadow: $white-box-shadow;
    //   border-radius: $border-radius;
    //   -moz-border-radius: $border-radius;
    //   -webkit-border-radius: $border-radius;
      .tc-audit-file-active {
        background-color: rgba(241, 242, 245, 0.5) !important;
      }
      .collapse-detail {
        // border-top: 1px solid #dddddd;
        // background-color: #fff;
        // border-bottom-left-radius: 0.55rem;
        // border-bottom-right-radius: 0.55rem;
        .card {
        //   padding: 10px;
          .card-body {
            .tc-top-detail {
              display: flex;
            }

            p {
              font-size: 14px;
            }

            .download-link {
              position: relative;
              top: 3px;
            }

            border: 1px solid #dddddd;
            border-radius: 0.35rem;
            .tc-upload-btn {
              text-align: right;
              button {
                background-color: #c0c1c2;
                border-color: #c0c1c2;
                padding-right: 20px;
                padding-left: 20px;
              }
              input {
                position: absolute;
                opacity: 0;
              }
              .upload-load-active {
                background-color: #3c68c3;
                border-color: #3c68c3;
              }
              .fa {
                line-height: 1.5;
              }
            }
            .tc-details-left {
              .tc-title {
                font-size: 16px;
                font-family: 'HelveticaNeue-Medium';
              }
              span {
                display: flex;
                a {
                  text-decoration: none;
                  display: flex;
                  .fa {
                    line-height: 1.3;
                    font-size: 17px;
                    color: #212529;
                  }
                  p {
                    text-decoration: underline;
                    color: $primary-color;
                  }
                }
              }
            }
            .tc-progress-bar {
              list-style: none;
              display: flex;
              padding-left: 0;
              justify-content: center;
              li {
                span {
                  text-align: center;
                  p {
                    border: 1px solid #dddddd;
                    height: 28px;
                    width: 28px;
                    border-radius: 50%;
                    margin: 0 auto;
                    padding: 1px;
                    color: #dddddd;
                  }
                  .check-icon {
                    background-color: $primary-color;
                    border-color: $primary-color !important;
                    .fa {
                      color: #fff;
                    }
                  }
                  .cross-icon {
                    border-color: red !important;
                    .fa {
                      color: red;
                    }
                  }
                }
              }
              hr {
                width: 25%;
                margin: 0.8rem -1.2rem 1rem !important;
                height: 2px;
                opacity: 1;
                background-color: #dddddd;
              }
            }
            .view-success-screen {
              text-align: right;
              position: relative;
              top: -42px;
              a {
                color: #eb1db4;
                font-size: 15px;
              }
            }
            .result-title {
              font-size: 16px;
              font-family: 'HelveticaNeue-Medium';
              margin-top: 20px;
              margin-bottom: 5px;
            }
            .success-title {
              .fa {
                font-size: 20px;
                color: #378107;
                margin-right: 6px;
              }
            }
            .error-title {
              .fa {
                font-size: 20px;
                color: #eb4800;
                margin-right: 6px;
              }
            }
            .error-msg {
              background-color: #ffefe8;
              color: #eb4800;
              padding: 10px;
              margin-bottom: 10px;
              border-radius: 4px;
              -moz-border-radius: 4px;
              -webkit-border-radius: 4px;
              .fa {
                font-size: 20px;
              }
            }
            .success-msg {
              background-color: #eaf8e1;
              color: #378107;
              padding: 8px;
              border-radius: 0.2rem;
              .fa {
                font-size: 20px;
              }
            }
          }
          .start-audit-btn {
            .btn {
              background-color: #c0c1c2;
              border-color: #c0c1c2;
              box-shadow: none;
              padding: 0.375rem 0.98rem !important;
            }
          }
        }
        .audit-result-section {
          .nav-tabs {
            border-bottom: unset;
            .nav-item {
              .nav-link.active {
                background-color: $primary-color;
                border: 1px solid $primary-color;
                color: #fff;
              }
              .nav-link {
                padding: 0.77rem 2.999rem;
                border-top-left-radius: 0.55rem;
                border-top-right-radius: 0.55rem;
                outline: none;
                box-shadow: none;
                display: flex;
                background-color: #fff;
                border-color: #dee2e6 #dee2e6 #fff;
                color: #212529;
                border-bottom: unset;
                p {
                  line-height: 1.8;
                }
                hr {
                  opacity: 1;
                  width: 1px;
                  margin: 4px 10px 0px 8px;
                  height: 24px;
                  color: #dee2e6;
                }
              }
            }
          }
          .tab-content {
            .tab-pane {
              border: 1px solid #dddddd;
              background-color: #f8f8fa80;
              border-top-right-radius: 0.55rem;
              border-bottom-right-radius: 0.55rem;
              border-bottom-left-radius: 0.55rem;
            }
            .first-toggle-tab {
              .top-transformer-tab {
                .card {
                  border: unset;
                  background-color: unset;
                }

                .main-tc-table {
                  background-color: #fff;
                  padding: 1rem 0rem 0rem 0rem;
                  border-top-right-radius: 0.55rem;
                  .search-heading {
                    display: flex;
                    .search-space {
                      input {
                        box-shadow: none;
                        padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                        margin-right: 89px;
                      }
                      .fa {
                        position: absolute;
                        top: 9px;
                        right: 11px;
                      }
                    }
                  }
                  .download-result {
                    .download-bt {
                      display: inline-flex;
                      line-height: 1.5;
                      color: $base-color;
                      text-decoration: none;
                      cursor: pointer;
                      vertical-align: middle;
                    }
                    .btn {
                      display: inline-flex;
                      background-color: #ececed;
                      border-color: #ececed;
                      color: $base-color;
                    }
                  }
                  .table {
                    border-top: 1px solid #dddddd;
                    thead {
                      tr {
                        th {
                          border-bottom: 1px solid #dddddd;
                        }
                      }
                    }
                    tbody {
                      tr {
                        td {
                          a {
                            .fa {
                              color: rgba(33, 37, 41, 1);
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .pagination-1 {
                  width: 100%;
                  background: #fff;
                  border-bottom-left-radius: 0.55rem;
                  border-bottom-right-radius: 0.55rem;
                  .pagination {
                    justify-content: flex-end;
                    .page-item {
                      .page-link {
                        background-color: unset;
                        color: #212529;
                      }
                    }
                    .previous-item {
                      display: flex;
                      span {
                        line-height: 2.4;
                        font-size: 15px;
                      }
                      .active {
                        color: #3c68c3;
                      }
                    }
                  }
                }
              }
            }
            .second-toggle-tab {
              .top-transformer-tab {
                .card {
                  border: unset;
                  background-color: unset;
                  padding: 19px 19px 1px 19px;
                  .card-body {
                    display: flex;
                    padding: 1rem 1.7rem;
                    background-color: #fff;
                    p {
                      line-height: 1.7;
                      font-size: 16px;
                    }
                    hr {
                      opacity: 1;
                      width: 1px;
                      margin: 4px 19px 0px 0px;
                      height: 24px;
                      color: #dee2e6;
                    }
                  }
                }
                .main-tc-table {
                  background-color: #fff;
                  padding: 1rem 0rem 0rem 0rem;
                  border-top: 1px solid #dddddd;
                  border-top-right-radius: 0.55rem;
                  .search-heading {
                    display: flex;
                    .search-space {
                      .form-control {
                        box-shadow: none;
                        margin-right: 89px;
                      }
                      .fa {
                        position: absolute;
                        top: 9px;
                        right: 11px;
                      }
                    }
                    .download-result {
                      .download-bt {
                        display: inline-flex;
                        line-height: 1.5;
                        color: $base-color;
                        text-decoration: none;
                        cursor: pointer;
                        vertical-align: middle;
                      }
                      .btn {
                        display: inline-flex;
                        background-color: #ececed;
                        border-color: #ececed;
                        color: $base-color;
                      }
                    }
                  }
                  .table {
                    border-top: 1px solid #dddddd;
                    thead {
                      tr {
                        th {
                          border-bottom: 1px solid #dddddd;
                        }
                      }
                    }
                    tbody {
                      tr {
                        td {
                          a {
                            .fa {
                              color: rgba(33, 37, 41, 1);
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .pagination-1 {
                  width: 100%;
                  background: #fff;
                  border-bottom-right-radius: 0.55rem;
                  border-bottom-left-radius: 0.55rem;
                  .pagination {
                    justify-content: flex-end;
                    .page-item {
                      .page-link {
                        background-color: unset;
                        color: #212529;
                      }
                    }
                    .previous-item {
                      display: flex;
                      span {
                        line-height: 2.4;
                        font-size: 15px;
                      }
                      .active {
                        color: #3c68c3;
                      }
                    }
                  }
                }
              }
            }
            .third-toggle-tab {
              .top-transformer-tab {
                .card {
                  border: unset;
                  background-color: unset;
                  padding: 19px 19px 1px 19px;
                  .card-body {
                    display: flex;
                    padding: 1rem 1.7rem;
                    background-color: #fff;
                    p {
                      line-height: 1.7;
                    }
                    hr {
                      opacity: 1;
                      width: 1px;
                      margin: 4px 19px 0px 0px;
                      height: 24px;
                      color: #dee2e6;
                    }
                  }
                }
                .main-tc-table {
                  background-color: #fff;
                  padding: 1rem 0rem 0rem 0rem;
                  border-top: 1px solid #dddddd;
                  border-top-right-radius: 0.55rem;
                  .search-heading {
                    display: flex;
                    .search-space {
                      .form-control {
                        box-shadow: none;
                        margin-right: 89px;
                      }
                      .fa {
                        position: absolute;
                        top: 9px;
                        right: 11px;
                      }
                    }
                    .download-result {
                      .download-bt {
                        display: inline-flex;
                        line-height: 1.5;
                        color: $base-color;
                        text-decoration: none;
                        cursor: pointer;
                        vertical-align: middle;
                      }
                      .btn {
                        display: inline-flex;
                        background-color: #ececed;
                        border-color: #ececed;
                        color: $base-color;
                      }
                    }
                  }
                  .table {
                    border-top: 1px solid #dddddd;
                    thead {
                      tr {
                        th {
                          border-bottom: 1px solid #dddddd;
                        }
                      }
                    }
                    tbody {
                      tr {
                        td {
                          a {
                            .fa {
                              color: rgba(33, 37, 41, 1);
                            }
                          }
                          .audit {
                            text-decoration: none;
                            background: $primary-color;
                            padding: 2px 10px 3px 10px;
                            border-radius: 0.2rem;
                            color: #fff;
                            font-size: 14px;
                          }
                        }
                      }
                    }
                  }
                }
                .pagination-1 {
                  width: 100%;
                  background: #fff;
                  border-bottom-left-radius: 0.55rem;
                  border-bottom-right-radius: 0.55rem;
                  .pagination {
                    justify-content: flex-end;
                    .page-item {
                      .page-link {
                        background-color: unset;
                        color: #212529;
                      }
                    }
                    .previous-item {
                      display: flex;
                      span {
                        line-height: 2.4;
                        font-size: 15px;
                      }
                      .active {
                        color: #3c68c3;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}